<template>
    <div class="action-notice-assign-manager">
        <div class="title f-bold" v-html="$translate('ACTION_NOTICE_ASSIGN_MANAGER_TITLE')" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionNoticeAssignManager',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            if (!this.chat) return

            return (this.chat || {}).user
        },
        body() {
            const body = this.$translate('ACTION_NOTICE_ASSIGN_MANAGER_BODY')
            return body
        },
    },
}
</script>
